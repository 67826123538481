import Link from "next/link";
import Image from "next/image";

function GenericError() {
  return (
    <main className="bg-primary-01 w-100 h-100">
      <div className="container-xl py-7">
        <div className="py-lg-7 d-flex flex-column flex-lg-row align-items-center justify-content-center gap-5">
          <div className="me-lg-5">
            <Image
              src="/looking-ahead.svg"
              alt="error page"
              width={231}
              height={165}
            />
          </div>
          <div className="d-lg-flex flex-column align-items-baseline mt-4 mt-lg-0">
            <h4 className="fw-normal">Something went wrong</h4>
            <p className="m-0 text-body-01">
              We can’t find what you’re looking for.
            </p>
            <p className="m-0 text-body-01">Please go back to home.</p>
            <div className="mt-4 mt-lg-5">
              <Link href="/" className="btn btn-primary p-12px">
                <p className="m-0 px-2">Go to home</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default GenericError;
