import Image from "next/image";
import Link from "next/link";
import FastlaneLogotypeWhiteSVG from "purple-rain/assets/fastlane-logotype-white.svg";

export default function Header() {
  return (
    <header className="bg-gradient">
      <nav className="navbar navbar-dark">
        <Link href="/">
          <Image
            src={FastlaneLogotypeWhiteSVG}
            width={119}
            alt="fastlane logo"
          />
        </Link>
      </nav>
    </header>
  );
}
