"use client";

import { GenericError } from "@/ui/components";

export default function GlobalError() {
  return (
    <html>
      <body>
        <GenericError />
      </body>
    </html>
  );
}
