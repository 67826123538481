"use client";

import Image from "next/image";
import FastlaneLogotypeWhiteSVG from "purple-rain/assets/fastlane-logotype-white.svg";
import { useI18n } from "@/locales/client";

export default function Footer() {
  const t = useI18n();

  return (
    <footer className="bg-gradient">
      <section className="container-md d-grid gap-md-6 py-5 pb-md-6 pt-md-7">
        <Image src={FastlaneLogotypeWhiteSVG} width={163} alt="fastlane logo" />
        <div className="w-100 text-indigo-2 pt-6 pb-40px p-md-0">
          <hr className=" opacity-100 m-0 " />
        </div>
        <p className="text-caption text-white text-typography-dark-background-high-emphasis">
          {t("ui.footer.legal")}
        </p>
      </section>
    </footer>
  );
}
